<ng-template #content>
  <li routerLinkActive="active">
    <a href="" (click)="$event.preventDefault()">
      <i class="fas fa-home"></i>
      <span class="title">MY TASKS</span>
    </a>
    <ul class="sub-menu">
      <li routerLinkActive="active">
        <a routerLink="/filer/my-tasks"> My Tasks </a>
      </li>
    </ul>
  </li>
  <li routerLinkActive="active">
    <a href="" (click)="$event.preventDefault()">
      <i class="fas fa-cog"></i>
      <span class="title">SETTINGS</span>
    </a>
    <ul class="sub-menu">
      <li routerLinkActive="active">
        <a routerLink="/filer/contact-info"> My Contact Info </a>
      </li>
      <li routerLinkActive="active">
        <a routerLink="/filer/designees"> My Designees </a>
      </li>
    </ul>
  </li>
  <li routerLinkActive="active">
    <a href="" (click)="$event.preventDefault()">
      <i class="fas fa-question-circle"></i>
      <span class="title">HELP</span>
    </a>
    <ul class="sub-menu">
      <li>
        <a
          *ngIf="!!menuItemsService.userResourcesDocument"
          (click)="menuItemsService.download(menuItemsService.userResourcesDocument)"
        >Integrity User Resources
        </a>
      </li>
      <li routerLinkActive="active">
        <a routerLink="/filer/help/agency-support"> Agency Support </a>
      </li>
      <li routerLinkActive="active">
        <a routerLink="/filer/help/form278e-instructions"> 278e Instructions </a>
      </li>
      <li routerLinkActive="active">
        <a routerLink="/filer/help/form278t-instructions"> 278-T Instructions </a>
      </li>      
      <li>
        <a
          (click)="
            window.open('https://www.oge.gov/Web/278eGuide.nsf', '_blank')
          "
        >
          Public Financial Disclosure Guide
        </a>
      </li>
    </ul>
  </li>
  <li>
    <a routerLink="/logout">
      <i class="fas fa-sign-out-alt"></i>
      <span class="title">LOG OUT</span>
    </a>
  </li>
</ng-template>
