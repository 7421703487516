<ng-template #content>
  <li routerLinkActive="active">
    <a href="" (click)="$event.preventDefault()">
      <i class="fas fa-tachometer-alt"></i>
      <span class="title">ADMINISTRATION</span>
    </a>
    <ul class="sub-menu">
      <li routerLinkActive="active">
        <a routerLink="/admin/manage">
          Manage
          <ng-container *ngIf="canManageAgencies">Agencies & </ng-container
          >Groups
        </a>
      </li>
      <li routerLinkActive="active">
        <a routerLink="/admin/find-users"> Find Users </a>
      </li>
      <li routerLinkActive="active">
        <a routerLink="/admin/management-reports"> Management Reports </a>
      </li>
      <li
        routerLinkActive="active"
        *ngIf="authorizationService.canAccessAnnualDataExtract()"
      >
        <a routerLink="/admin/annual-data-extract"> Annual Data Extract </a>
      </li>
      <li routerLinkActive="active">
        <a routerLink="/admin/provision-users-status">
          Provision Users Status
        </a>
      </li>
    </ul>
  </li>

  <li routerLinkActive="active">
    <a href="" (click)="$event.preventDefault()">
      <i class="fas fa-cog"></i>
      <span class="title">SETTINGS</span>
    </a>
    <ul class="sub-menu">
      <li routerLinkActive="active">
        <a routerLink="/admin/contact-info"> My Contact Info </a>
      </li>
    </ul>
  </li>
  <li routerLinkActive="active">
    <a href="" (click)="$event.preventDefault()">
      <i class="fas fa-question-circle"></i>
      <span class="title">HELP</span>
    </a>
    <ul class="sub-menu">
      <li>
        <a
          *ngIf="!!menuItemsService.userResourcesDocument"
          (click)="menuItemsService.download(menuItemsService.userResourcesDocument)"
        >Integrity User Resources
        </a>
      </li>
      <li routerLinkActive="active">
        <a routerLink="/admin/help/agency-support"> Agency Support </a>
      </li>
      <li routerLinkActive="active">
        <a routerLink="/admin/help/form278e-instructions"> 278e Instructions </a>
      </li>
      <li routerLinkActive="active">
        <a routerLink="/admin/help/form278t-instructions"> 278-T Instructions </a>
      </li>      
      <li>
        <a
          (click)="
            window.open('https://www.oge.gov/Web/278eGuide.nsf', '_blank')
          "
        >
          Public Financial Disclosure Guide
        </a>
      </li>
    </ul>
  </li>
  <li>
    <a routerLink="/logout">
      <i class="fas fa-sign-out-alt"></i>
      <span class="title">LOG OUT</span>
    </a>
  </li>
</ng-template>
